<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>行业列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div style="padding-bottom:10px">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAuth">新建行业</el-button>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="tableData"
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                style="width: 100%"
                v-loading="loading"
                row-key="id"
                border
                :expand-row-keys="defaultArr"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                >
                    <el-table-column
                    prop=""
                    align="center"
                    label=""
                    width="50">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    align="left"
                    label="行业名称"
                    width="200"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="isShow"
                    align="center"
                    label="是否显示"
                    width="200"
                    >
                    <template slot-scope="scope">
                            <el-switch
                            v-model="scope.row.isShow"
                            :active-value="true"
                            :inactive-value="false"
                            active-color="#404040"
                            inactive-color="#b8b8b8"
                            active-text=""
                            inactive-text=""
                            @change="changeIndustryIsShow($event,scope.row)"
                            >
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="id"
                    align="center"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="250"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="handleCreate(scope.row.id,scope.row.name,scope.row.parId)">编辑</el-button>
                            <!-- <el-tooltip class="item" effect="dark" content="添加子节点" placement="top-start"> -->
                                <el-button v-if="scope.row.children" type="primary" @click="openAuth1(scope.row.id,scope.row.parId)">添加</el-button>
                            <!-- </el-tooltip> -->
                            <el-button type="danger"   @click="deleteItem(scope.row.id,scope.row.parId)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 添加 -->
        <el-dialog title="新建行业" :visible.sync="dialogVisible1" :close-on-click-modal="false" :destroy-on-close="false" @close="canvel">
        <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formAdd"
            :rules="rules"
            ref="formAdd"
        >
            <el-form-item label="行业名称" prop="name">
            <el-input v-model="formAdd.name"></el-input>
            </el-form-item>
            <el-form-item class="flex justify-content-end">
            <el-button @click="canvel">取 消</el-button>
            <el-button type="primary" v-preventReClick @click="handAdd('formAdd')">添加</el-button>
            </el-form-item>
        </el-form>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="提示" :visible.sync="dialogVisible" :close-on-click-modal="false"	@close="dialogVisible = false;parId = null">
        <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formEdit"
            :rules="rules"
            ref="formEdit"
        >
            <el-form-item label="权限名称" prop="name">
            <el-input v-model="formEdit.name"></el-input>
            </el-form-item>
            <el-form-item class="flex justify-content-end">
            <el-button @click="dialogVisible = false;parId = null">取 消</el-button>
            <el-button type="primary" v-preventReClick @click="handEdit('formEdit')" :loading="btnLoad">修改</el-button>
            </el-form-item>
        </el-form>
        </el-dialog>
    </div>
</template>
<script>
import _api from '@/api/index'
import apiUrl from '@/api/public'
export default {
    data(){
        return{
            tableData: [], //权限列表数据
            defaultArr:[],//默认展开行
            dialogVisible:false,
            dialogVisible1:false,
            formEdit: {
                name:"",
            },
            formAdd: {
                name:"",
            },
            rules: {
                name: [
                { required: true, message: "行业名称不能为空", trigger: "blur" }
                ]
            },
            list:[],
            data:[],
            loading:false,
            
            labelPosition: "right", //控制表单对齐方式
            btnLoad:false,
            id:null,
            isShow:null,
            name:"",
            parId:"",
        }
    },
    created(){
        this.getList()
    },
    methods:{
        //新建行业
        openAuth(){
            this.dialogVisible1=true
            this.id1=1
        },
        openAuth1(id,parId){
            this.id = id
            this.parId = parId
            this.dialogVisible1=true
            this.id1=0
        },
        handleCreate(id,name,parId) {
            console.log(parId,name,id)
            this.id = id
            this.formEdit.name = name
            this.dialogVisible = true
            this.parId = parId
        },
        // 取消
        canvel(){
            this.dialogVisible1 = false
            this.btnLoad=false
            this.formAdd.name=''
            this.parId = null
        },
        //添加
        handAdd(formName) {
            this.btnLoad=true
            if(this.id1==1){
                this.id=0
            }
            const newChild = {
                name:this.formAdd.name,
                parId:this.id,
            };
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _api.post(apiUrl.addIndustry,newChild
                    ).then(res=>{
                        if (res.success) {
                            this.$message.success('添加成功')
                            this.canvel()
                            this.getList()
                        }
                    }).catch(err =>{
                        console.log(err)
                    })
                } else {
                this.$message.error("信息未填写完整，请检查！");
                return false;
                }
            });
        },
        handEdit(formName) {
            this.btnLoad=true
            const newChild = {
                id:this.id,
                parId:this.parId,
                name:this.formEdit.name,
            };
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _api.post(apiUrl.addIndustry,newChild
                    ).then(res=>{
                        if (res.success) {
                            this.$message.success('修改成功')
                            this.btnLoad=false
                            this.dialogVisible = false
                            this.parId = null
                            this.getList()
                        }
                    }).catch(err =>{
                        console.log(err)
                    })
                } else {
                this.$message.error("信息未填写完整，请检查！");
                return false;
                }
            });
        },
        getList(){
            this.loading=true
            _api.get(apiUrl.queryIndustry,{
            }
            ).then(res=>{
                this.loading=false
                if(res.success){
                    this.tableData=res.data
                }
            }).catch(err =>{
                console.log(err)
            })
        },
        deleteItem(id,parId){
            let messageText = ""
            if(parId==0){
                messageText = "若删除父行业，该行业下的所有子行业也将被删除，是否继续?"
            }else{
                messageText = "此操作将永久删除, 是否继续?"
            }
            this.$confirm(messageText, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                _api.get(apiUrl.deleteIndustry,{
                        industryId:id
                    }
                    ).then(res=>{
                        if (res.success) {
                            this.$message.success('删除成功')
                            this.getList()
                        }
                    }).catch(err =>{
                        console.log(err)
                    })
                })
                .catch(() => {
                });
        },
        getimgurl(data){
            this.formLabelAdd1.url=data
        },
        getimgurl1(data){
            this.formLabelAdd.url=data
        },
        changeIndustryIsShow(status,row){
            console.log(status,row)
            _api.post(apiUrl.changeIndustryIsShow,{
                id:row.id,
                isShow:status
            }).then(res=>{
                if(res.success){
                    this.$message.success("操作成功");
                }
            }).catch(err =>{
                console.log(err)
            })
        },
    }
}
</script>
<style>
 .el-loading-spinner .path{    stroke: #fe8196;}
 .el-switch__label{color: #bdbdbd;}
 .el-switch__label.el-switch__label--right.is-active{color: #2ad1b8;}
 .el-switch__label.el-switch__label--left.is-active{color: #fe8496;}
</style>
<style scoped>
@import "../../assets/css/booth.css";
.serarch{ overflow: hidden;}

</style>
